$prefix:'databot';

.#{$prefix}_userMenu {
    position: absolute;
    top: 50px;
    right: 1rem;
    width: 200px;
    padding: 0.25rem;
    background-color: white;
    text-align: center;
    z-index: 1;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    border-radius: 0.25rem;

    &-name {
        color: #393939;
        font-size: 15px;
    }

    &-avatar {
        margin-top: 5px;
        margin-right: 5px;
        background-color: #0f62fe !important;
    }

    &-link {
        text-decoration: none;
        text-align: left;
        color: #393939;

        &-title {
            padding: 0.5rem;
            border-radius: 0.25rem;
            margin: 0;
            &:hover {
                background-color: rgb(241, 239, 239);
            }
        }
    }

    &-logout {
        margin-top: 0.5rem;
        min-width: 165px;
        width: 100%;
        height: 50px;
        letter-spacing: 0.5px;
        padding: 15px 35px;
        font-size: 15px;
        background-color: #24292e;
        color: white;
        text-transform: uppercase;
        font-weight: bolder;
        border: none;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        border-top: 1px solid #393939;
        border-radius: 0.25rem;

        &:hover {
            background-color: white;
            color: #393939;
            border: 1px solid black;
        }
    }
}
$prefix: 'databot';

.#{$prefix}_querySection {
    padding: 1rem;
    background-color: white;
    width: calc(62vw + 2rem);
    margin: auto;
    margin-bottom: 2rem;
    background-color: #ffffff;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2);
    border-radius: 0.25rem;

    &-search {
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
    }

    &-databot-message {
        padding: 1rem;
        width: calc(62vw - 2rem);
        margin: auto;
        border: 0.5px solid #ccc;
        border-radius: 0.25rem;

        &-text {
            font-size: 16px;
            font-weight: 400;
        }
    }
}
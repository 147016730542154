$prefix: 'databot';

.#{$prefix}_commonSearchbar { 
    display: flex;

    &-button {
        font-weight: bolder;
        border: none;
        cursor: pointer;
        margin-left: -2.5rem;
        text-decoration: none;
        display: flex;
        background-color: none;
        border-radius: 0.25rem;
        margin: none;
        background-color: rgba(0, 0, 255, 0);
        color: rgb(89, 98, 117);
        justify-content: center;
        align-items: center;

        &:focus {
            outline: none;
            border: none;
        }
    }
}
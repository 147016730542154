$prefix: 'databot';

.#{$prefix}_homePage {
    display: flex;

    &--wrapper {
        width: 50vw;
        height: 100vh;
        background-color: #24292e;

        &-right {
            width: 50vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-welcome {
            margin-top: 5rem;
            width: 80%;
            background-color: rgb(241, 239, 239);
            padding: 1rem;
            border-radius: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &-text-conv {
                padding: 1rem;
                background: white;
                border-radius: 1rem 1rem 0 1rem;
                box-shadow:
                0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                0 12.5px 10px rgba(0, 0, 0, 0.06),
                0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                0 100px 80px rgba(0, 0, 0, 0.12);              
                font-weight: 700;
            }
        }

        &-buttons {
            width: 100%;

            &-button {
                min-width: 165px;
                width: calc(100% - 1rem);
                height: 50px;
                letter-spacing: 0.5px;
                padding: 15px 35px 15px 35px;
                font-size: 15px;
                background-color: #24292e;
                color: white;
                text-transform: uppercase;
                font-weight: bolder;
                border: none;
                cursor: pointer;
                text-decoration: none;
                border-radius: 0.25rem;
                margin-bottom: 1rem;
              
                &:hover {
                  background-color: white;
                  color: #393939;
                  border: 1px solid black;
                }

                &-invert {
                    min-width: 165px;
                    width: calc(100% - 1rem);
                    height: 50px;
                    letter-spacing: 0.5px;
                    padding: 15px 35px 15px 35px;
                    font-size: 15px;
                    background-color: white;
                    color: #393939;
                    border: 1px solid black;
                    text-transform: uppercase;
                    font-weight: bolder;
                    cursor: pointer;
                    text-decoration: none;
                    border-radius: 0.25rem;
                
                    &:hover {
                        background-color: #24292e;
                        color: white;
                    }
                }
            }
        }

        .#{$prefix}_logo {
            color: white;
            font-size: 1.5rem;
            text-decoration: none;
            padding-left: 1rem;
    
            &-text {
                height: 15px;
                margin-top: 17.5px;
            }
        }
    }
}
$prefix: 'databot';

.#{$prefix}_addConnection {

    &-content {

        &-button-test {
            min-width: 165px;
            width: 100%;
            height: 50px;
            letter-spacing: 0.5px;
            padding: 15px 35px 15px 35px;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: bolder;
            border: none;
            cursor: pointer;
            text-decoration: none;
            margin-bottom: 1rem;
            border-radius: 0.25rem;
            background-color: #0f62fe;
            color: white;

            &:hover {
                background-color: #024dda;
                border-top: 1px solid #024dda;
            }
        }
    }
}
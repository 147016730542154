$prefix: 'databot';

.#{$prefix}_leftSidebar {
    position: absolute;
    top: 49.5px;
    left: 0;
    width: 200px;
    height: calc(100vh - 49.5px);
    background-color:white;
    box-shadow: 5px 0 5px -5px #333;
    z-index: 999;
}
$prefix: 'databot';

.#{$prefix}_emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 16px;
    background-color: #24292e;

    &-title {
        font-size: 42px;
        color: white;
        padding: 0;
        margin: 0;
    }

    &-logo {
        width: 150px;
    }
}
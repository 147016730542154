$prefix: 'databot';

.#{$prefix}_navbar {
    background-color: #24292e;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;

    .#{$prefix}_burger {
      font-size: 1.5rem;
      height: 100%;
      text-decoration: none;
      padding: 1vh 1rem;
      border: none;
      background: none;

      &:hover {
        cursor: pointer;
        color: #ccc; 
      }
    }

    &-search {
      margin-left: 1rem;
    }

    .#{$prefix}_logo {
        color: white;
        font-size: 1.5rem;
        text-decoration: none;
        padding-left: 1rem;

        &-text {
            height: 15px;
            margin-top: 17.5px;
        }
    }

    .#{$prefix}_user--button {
        color: white;
        height: 100%;
        text-decoration: none;
        border: none;
        background: none;
        padding: 0 1rem;

        &-avatar {
          background-color: #0f62fe !important;
          color: white !important;
          margin-right: 0.25rem;
          margin: 0.5vh;
        }

        &-notifCount {
          background-color: #d31a1a;
          color: white;
          position: relative;
          border-radius: 0.5rem;
          left: 5px;
          top: 10px;
          height: 1rem;
          width: 1rem;
        }

        &:hover {
          cursor: pointer;
          color: #ccc; 
        }
    }

    .#{$prefix}_notification--button {
      color: white;
      height: 100%;
      text-decoration: none;
      border: none;
      background: none;
      padding: 0 1rem;

      &-notifCount {
        background-color: #d31a1a;
        color: white;
        position: relative;
        border-radius: 0.5rem;
        left: 5px;
        top: 10px;
        height: 1rem;
        width: 1rem;
      }

      &:hover {
        cursor: pointer;
        color: #ccc; 
      }
  }
}

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
    width: 60px;
    height: 45px;
    position: relative;
    margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background: #d3531a;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  #nav-icon1 span:nth-child(1) {
    top: 0px;
  }
  
  #nav-icon1 span:nth-child(2) {
    top: 18px;
  }
  
  #nav-icon1 span:nth-child(3) {
    top: 36px;
  }
  
  #nav-icon1.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  
  #nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  #nav-icon1.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
$prefix: 'databot';

.#{$prefix}_profilePage-overview {
    background-color: rgb(241, 239, 239);
    padding-top: 2rem;
    height: calc(100vh - 295px);
    overflow-y: auto;
    display: flex;
    padding-bottom: 2rem;

    &-content-card {
        padding: 1rem;
        background-color: white;
        width: 350px;
        margin-left: 2rem;
        margin-bottom: 2rem;
        background-color: #ffffff;
        position: relative;
        box-sizing: border-box;
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2);
        border-radius: 0.25rem;
    }
}
$prefix: 'databot';

.#{$prefix}_leftNavItem {
    width: 100%;
    height: calc(4rem - 0.5px);
    
    &-button {
        background-color: white;
        color: #24292e;
        cursor: pointer;
        padding: 10px;
        display: flex;
        align-items: center;
        border: none;
        text-decoration: none;
        outline: none;
        transition: background-color 0.6s ease;
        

        &-title {
            text-align: left;
            font-weight: 400;
            font-size: 15px;
            margin-left: 1rem;
        }

        &:hover {
            background-color: rgb(241, 239, 239);
        }
    }
}
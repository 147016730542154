$prefix:'databot';

.#{$prefix}_notificationMenu {
    position: absolute;
    top: 50px;
    right: 1rem;
    width: 300px;
    padding: 0.5rem;
    background-color: white;
    z-index: 1;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    border-radius: 0.25rem;

    a {
        text-decoration: none;
    }
    &-link {
        text-align: left;
        color: #393939;
        padding: 0.5rem;
        border-radius: 0.25rem;

        &-title {
            padding: 0.5rem;
            margin: 0;
        }

        &-time {
            font-size: 12px;
            padding: 0.5rem;
            margin: 0;
            margin-top: -0.5rem;
        }

        &-not-read {
            background-color: #0f62fe;
            border-radius: 1rem;
            margin: 0.5rem;
            margin-left: 1.5rem;  
            height: 0.85rem;
            width: 1rem;
        }

        &:hover {
            background-color: rgb(241, 239, 239);
        }
    }
}
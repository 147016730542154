$prefix: 'databot';

.#{$prefix}_modalHeader {
    color: white;
    background-color: #24292e;
    position: relative;
    top:-16px;
    right: 32px;
    padding: 16px 32px;
    margin-bottom: 1rem;
    width: calc(100%);
    
    &-title {
        margin: 0;
    }

    &-close {
        font-size: 1.5rem;
        height: 100%;
        text-decoration: none;
        border: none;
        background: none;
        color: white;

        &:hover {
            cursor: pointer;
            color: #ccc; 
        }
    }
}
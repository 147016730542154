$prefix: 'databot';

@mixin object-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.#{$prefix}_hover {
    &:hover {
        cursor: pointer;
    }
}

.#{$prefix}_updateUserModal {

    &-content {
        &-avatar {
            margin-top: 5px;
            height: 7.5rem !important;
            width: 7.5rem !important;
            font-size: 3rem !important;
            background-color: #0f62fe !important;
        }

        &-button {
            min-width: 165px;
            width: 100%;
            height: 50px;
            letter-spacing: 0.5px;
            padding: 15px 35px 15px 35px;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: bolder;
            border: none;
            background-color: #24292e;
            color: white;
            cursor: pointer;
            text-decoration: none;
            margin-bottom: 1rem;
            border-radius: 0.25rem;

            &:hover {
                background-color: white;
                color: #393939;
                border: 1px solid black;
            }

            &-save {
                min-width: 165px;
                width: 100%;
                height: 50px;
                letter-spacing: 0.5px;
                padding: 15px 35px 15px 35px;
                font-size: 15px;
                text-transform: uppercase;
                font-weight: bolder;
                border: none;
                cursor: pointer;
                text-decoration: none;
                margin-bottom: 1rem;
                border-radius: 0.25rem;
                background-color: #36a761;
                color: white;

                &:hover {
                    background-color: #338a54;
                    border-top: 1px solid #338a54;
                }
            }
        }
    }
}
$prefix: 'databot';

.#{$prefix}_workspaceHeader {
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    border-bottom: 0.5px solid #ccc;
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin-bottom: 2rem;

    &-title {
        letter-spacing: 0.5px;
        font-size: 15px;
        margin: 0;
        padding-left: 1rem;
        margin-right: 0.5rem; 
        text-transform: uppercase;
    }

    &-active {
        height: 10px;
        width: 10px;
        border-radius: 5px;
        margin-top: 1.6rem;
        background: green;
    }

    &-button {
        padding-right: 1rem;
        text-decoration: none;
        padding: 1.2rem 1rem;
        border: none;
        background: none;

        &:hover {
            background-color: #ccc;     
            cursor: pointer;
        }
    }
}
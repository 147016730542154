$prefix: 'databot';

.#{$prefix}_tablePage {
    
    &-content {
        position: absolute;
        top: 50px;
        right: 0;
        width: 100%;
        height: calc(100vh - 51px);

        &-header {
            border-bottom: 0.5px solid #ccc;
            margin-bottom: 15px;
        }

        .react-search-field {
            width: 500px !important;
            margin: 15px;
            margin-top: 0;

            &-input {
                height: auto !important;
            }
        }

        &-title {
            padding: 15px;
            font-size: 42px;
            margin-bottom:-20px;
            margin-top: 0;
        }

        &-breadcrums {
            padding:  15px;
            font-size: 32px;
        }
    }

    &-table {
        display: flex;
        justify-content: center;
    }
}
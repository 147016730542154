$prefix: 'databot';

.#{$prefix}_datafeedCard {
    padding: 1rem;
    background-color: white;
    width: 650px;
    margin: auto;
    margin-bottom: 2rem;
    background-color: #ffffff;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2);
    border-radius: 0.25rem;

    &-user-info {
        display: flex;
        justify-content: space-between;
    }

    &-avatar {
        background-color: #0f62fe !important;
        color: white !important;
        margin-top: 3px;
        margin-right: 1rem;
    }

    &-name {
        margin: 0;
        padding: 15px 0;
        font-size: 15px;
        text-decoration: none;
        font-weight: 700;
        color: black;

        &:hover {
            color: #0f62fe;
        }
    }

    &-date {
        color: rgba(var(--sk_foreground_max_solid,97,96,97),1);
        font-size: 12px;
        padding: 5px 5px;
    }

    &-wrapper-table {
        &-stats {
            font-size: 15px;
            margin-top: 0;
            font-weight: 400;
        }
    }

    &-message {
        font-size: 15px;
        font-weight: 400;
        padding-bottom: 1rem;
        padding-top: 1rem;
        margin: 0;
    }

    &-action {
        border-bottom: 0.5px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;

        &-button {
            min-width: 300px;
            font-weight: bolder;
            border: none;
            height: 44px;
            cursor: pointer;
            text-decoration: none;
            display: flex;
            background-color: none;
            border-radius: 0.25rem;
            margin: none;
            background-color: rgba(0, 0, 255, 0);
            color: rgb(89, 98, 117);
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: rgb(241, 239, 239);
            }
        }
    }

    &-wrapper-table {
        border-bottom: 0.5px solid #ccc;
    }

    &-comment {
        padding: 1rem 0 0 0;
        display: flex;

        &-input {
            padding: 1rem;
            width: calc(100% - 2rem);
            border-radius: 0.25rem;
            border-width: 1px;
        }

        &-wrapper {
            padding: 0.5rem;
            border-radius: 0.25rem;
            background-color: rgb(241, 239, 239);

            &-message {
                margin: 0;
                margin-top: 0.5rem;
            }
        }

    }
}
$prefix: 'databot';

.#{$prefix}_loadingState { 
    
    &-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 99vh;

        &-text {
            height: 50px;
        }
    }
}
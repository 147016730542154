$prefix: 'databot';

.#{$prefix}_datafeedPage {
    
    &-content {
        background-color: rgb(241, 239, 239);
        position: absolute;
        right: 0;
        width: 100%;
        height: calc(100vh - 51px);
    }
}
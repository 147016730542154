$prefix: 'databot';

.#{$prefix}_profileNavbar {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #ccc;


    &-links {
        display: flex;
        margin-left: 2rem;
        &-item {
            margin-right: 1rem;
            display: flex;
            text-decoration: none;
            color: #393939;
            cursor: pointer;
    
            &:hover {
                border-bottom: 3px solid #ccc;
            }
    
            &-title {
                margin-left: 5px;
            }
        }
    }

    &-buttons {
        width: 14rem;
        margin-right: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;

        &-update {
            letter-spacing: 0.5px;
            padding: 5px 5px;
            font-size: 1.17em;
            height: 36px;
            background-color: #24292e;
            color: white;
            font-weight: bolder;
            border: none;
            cursor: pointer;
            text-decoration: none;
            display: flex;
            text-overflow: ellipsis;
            border-top: 1px solid #393939;
            border-radius: 0.25rem;
    
            &:hover {
                background-color: white;
                color: #393939;
                border: 1px solid black;
            }
        }

        &-follow {
            letter-spacing: 0.5px;
            padding: 5px 5px;
            font-size: 1.17em;
            height: 36px;
            background-color: #0f62fe;
            color: white;
            font-weight: bolder;
            border: none;
            cursor: pointer;
            text-decoration: none;
            display: flex;
            text-overflow: ellipsis;
            border-radius: 0.25rem;
            margin-top: 0.25rem;

            p {
                margin: none;
            }
    
            &:hover {
                background-color: #024dda;
            }
        }
    }
}
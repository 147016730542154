$prefix: 'databot';

.#{$prefix}_gettingStartedPage {
    display: flex;

    &-content {
        max-width: 60%;
        margin-left: calc(200px + 2rem);
        height: calc(100vh - 55px);

        &-title {
            font-size: 32px;

            &-sub {
                font-size: 28px;
                font-weight: 500;
            }
        }

        &-paragraph {
            font-size: 1rem;
            margin-top: -5px;
        }

        &-try {
            margin: 24px 0;
            padding: 16px;
            overflow: auto;
            direction: ltr;
            border-radius: 4px;
            background-color: #272c34;
        }

        &-code {
            color: #fff;
            line-height: 1.5;
            background-color: #272c34;
        }
    }

    &-nav {
        margin-left: 3rem;
        margin-top: 1rem;

        &-title {
            
            &-sub {
                font-size: .8125rem;
                box-sizing: border-box;
                color: rgba(0, 0, 0, 0.54);
            }
        }
    }
}
$prefix: 'databot';

.#{$prefix}_dashboardPage {

    &--content {
        position: absolute;
        top: 50px;
        right: 0;
        width: 100%;
        height: calc(100vh - 51px);
        background-color: #f1efef;
    }
}
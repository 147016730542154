$prefix: 'databot';

.#{$prefix}_wordBank {
    display: flex;

    &-content {
        max-width: 60%;
        margin-left: calc(200px + 2rem);
        height: calc(100vh - 55px);
        padding-bottom: 2rem;
        height: calc(100vh - 50px - 2rem);
        overflow-y: auto;

        &-title {
            font-size: 32px;

            &-sub {
                font-size: 28px;
                font-weight: 500;
            }
        }

        &-paragraph {
            font-size: 1rem;
            margin-top: -5px;
        }
    }

    &-nav {
        margin-left: 3rem;
        margin-top: 1rem;

        &-title {
            
            &-sub {
                font-size: .8125rem;
                box-sizing: border-box;
                color: rgba(0, 0, 0, 0.54);
            }
        }
    }
}
$prefix: 'databot';

.#{$prefix}_deleteModal {

    &-cancel {
        padding: 0.5rem 1rem;
        font-size: 18px;
        color: #0f62fe;
        
        text-decoration: none;
        background-color: white;
        border: none;
        border-radius: 0.25rem;
        cursor: pointer;

        &:hover {
            background-color: rgb(241, 239, 239);
        }
    }

    &-delete {
        padding: 0.5rem 1rem;
        margin-left: 0.5rem;
        background-color: #0f62fe;
        cursor: pointer;
        color: white;
        font-size: 18px;
        border: none;
        border-radius: 0.25rem;

        &:hover {
            background-color: #024dda;
        }
    }
}
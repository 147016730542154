$prefix: 'databot';

.#{$prefix}_datafeedNavbar {
    width: 100%;
    height: 4rem;
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
    background-color: white;
    display: flex;
    justify-content: center;
    border-bottom: 0.5px solid #ccc;

    &-item {
        margin-right: 1rem;
        display: flex;
        text-decoration: none;
        color: #393939;
        cursor: pointer;

        &:hover {
            border-bottom: 3px solid #ccc;
        }

        &-title {
            margin-left: 5px;
        }
    }
}
$prefix: 'databot';

.#{$prefix}_detailsSidebar {
    position: absolute;
    top: 50px;
    right: 0;
    width: 299px;
    height: calc(100vh - 50px);
    border: 1px solid #ccc;
    border-top: none;
    border-bottom: none;

    &--header {
        width: 100%;
        height: 4rem;
        line-height: 4rem;
        border-bottom: 0.5px solid #ccc;
        display: flex;
        justify-content: space-between;

        &-title {
            letter-spacing: 0.5px;
            font-size: 15px;
            margin: 0;
            margin-top: -0.5rem; 
            padding-left: 1rem;
            margin-right: 0.5rem; 
        }

        &-subtitle {
            letter-spacing: 0.5px;
            font-size: 10px;
            margin: 0;
            margin-top: -2.75rem;
            padding-left: 1rem;
            margin-right: 0.5rem; 
            color: #393939;
            text-transform: uppercase;
        }

        &-button {
            padding-right: 1rem;
            text-decoration: none;
            padding: 1.2rem 1rem;
            border: none;
            background: none;
    
            &:hover {
                background-color: #ccc;     
                cursor: pointer;
            }
        }
    }

    &--content {
        padding-top: 2rem; 
        width: 100%;
        text-align: center;

        &-title {
            text-transform: uppercase;
        }

        &-about {
            text-align: left;
            border-top: 0.5px solid #ccc;
            &-title {
                letter-spacing: 0.5px;
                font-size: 15px;
                margin: 0;
                margin-top: 1rem; 
                padding-left: 1rem;
                margin-right: 0.5rem; 
            }

            &-subtitle {
                letter-spacing: 0.5px;
                font-size: 10px;
                margin: 0;
                margin-top: 1rem;
                padding-left: 1rem;
                margin-right: 0.5rem; 
                color: #393939;
            }
        }
        
    }
}
$prefix: 'databot';

.#{$prefix}_profilePage {

    &-content {
        position: absolute;
        top: 50px;
        right: 0;
        width: 100%;
        height: calc(100vh - 51px);

        &-empty {
            color: #393939;
        }

        &-name {
            padding: 15px;
            font-size: 24px;
            margin-bottom:-20px;
            margin-top: 0;
        }
    
        &-avatar {
            margin-top: 5px;
            margin-right: 5px;
            height: 5rem !important;
            width: 5rem !important;
            font-size: 2rem !important;
            background-color: #0f62fe !important;
        }

        &-follow-stats {
            padding: 10px 35px;
            font-size: 15px;
            margin-bottom:-20px;
            margin-top: 0;
            font-weight: 400;
        }

        &-databases {
            display: flex;
            padding: 15px;

            &-card {
                padding: 15px;
                border: 0.5px solid rgb(204, 204, 204);
                border-radius: 5px;
                width: calc(100% - 2rem);

                &-about {
                    &-subtitle {
                        letter-spacing: 0.5px;
                        font-size: 10px;
                        margin-right: 0.5rem; 
                        color: #393939;
                        text-transform: uppercase;
                    }
                }
            }
        }

        &-title {
            padding: 15px;
            margin: 0;
        }
    }
}
$prefix: 'databot';

.#{$prefix}_userConnections {
    padding: 15px;
    &-search {
        padding: 15px 0;
        display: flex;

        .react-search-field {
            width: 500px !important;
            margin-top: 0;

            &-input {
                height: auto !important;
            }
        }

        &-new {
            height: 35px;
            letter-spacing: 0.5px;
            padding: 5px;
            margin-left: 1rem;
            font-size: 15px;
            background-color: #36a761;
            color: white;
            text-transform: uppercase;
            font-weight: bolder;
            border: none;
            cursor: pointer;
            text-decoration: none;
            display: flex;
            border-top: 1px solid #36a761;
            border-radius: 0.25rem;

            &:hover {
                background-color: #338a54;
                border-top: 1px solid #338a54;
            }
        }
    }

    &-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        
        &-card {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 0.5px solid #ccc;
            &-about {
                &-subtitle {
                    letter-spacing: 0.5px;
                    font-size: 10px;
                    margin-right: 0.5rem; 
                    color: #393939;
                    text-transform: uppercase;
                }
            }
        }
    }
}
$prefix: 'databot';

.#{$prefix}_registerPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .google-button {
        height: 50px;
        border-width: 0;
        width: calc(100%);
        background: white;
        color: #737373;
        border-radius: 5px;
        white-space: nowrap;
        box-shadow: 1px 1px 0px 1px rgba(0,0,0,0.05);
        transition-property: background-color, box-shadow;
        transition-duration: 150ms;
        transition-timing-function: ease-in-out;
        padding: 0;
        
        &:focus,
        &:hover {
          box-shadow: 1px 4px 5px 1px rgba(0,0,0,0.1);
          cursor: pointer;
        }
        
        &:active {
          background-color: #e5e5e5;
          box-shadow: none;
          transition-duration: 10ms;
        }
      }
          
      .google-button__icon {
        display: inline-block;
        vertical-align: middle;
        margin: 8px 0 8px 8px;
        width: 18px;
        height: 18px;
        box-sizing: border-box;
      }
      
      .google-button__icon--plus {
        width: 27px;
      }
      
      .google-button__text {
        display: inline-block;
        vertical-align: middle;
        padding: 0 24px;
        font-size: 14px;
        font-weight: bold;
        font-family: 'Roboto',arial,sans-serif;
      }

    &--wrapper {
        width: 400px;
        display: flex;
        flex-direction: column;
        position: relative;
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
        border-radius: 0.25rem;
        padding: 1rem;

        &-title {
            margin: 0;
            margin-top: 1rem;
        }

        &-error {
            font-size: 12px;
            color: rgb(190, 47, 47);
            margin-bottom: 1rem;
            margin-top: -1rem;
        }

        &-buttons {
            display: flex;
            flex-wrap: wrap; 
            width: 100%;
            margin-top: 1rem;
        }

        &-button {
            min-width: 165px;
            width: 100%;
            height: 50px;
            letter-spacing: 0.5px;
            padding: 15px 35px 15px 35px;
            font-size: 15px;
            background-color: #24292e;
            color: white;
            text-transform: uppercase;
            font-weight: bolder;
            border: none;
            cursor: pointer;
            text-decoration: none;
            margin-bottom: 1rem;
            border-radius: 0.25rem;
          
            &:hover {
              background-color: white;
              color: #393939;
              border: 1px solid black;
            }
            &-google {
                width: 400px;
                height: 50px;
                padding-left: 100px !important;
            }
        }
    }
}

.#{$prefix}__registerPage--wrapper-third-party-wrapper {
    color: #696c6f;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;

    &-line {
        display: inline-block;
        width: 100%;
        height: 1px;
        background: #cdcfd2;
        vertical-align: middle;
    }

    &-text {
        position: absolute;
        left: 190px;
        padding: 0 20px;
        background: white;
    }
}

.abcRioButtonContents {
    margin-left: -40px !important;
}

